<template>
  <div class="container">
    <Van-Navbar :showLeftarrow="true" :title="lang.route_misslesson">
    </Van-Navbar>
    <div style="width: 100%; flex: 1; overflow-y: auto">
      <div :class="['searchstudent', isShowSearch ? '' : 'hasStudent']">
        <div v-if="isShowSearch">
          <img class="data_search" :src="common.getRes('data_search.png')" />
          <van-cell-group inset>
            <van-field
              v-model="studentId"
              center
              :placeholder="lang.search_placeholder"
            >
              <template #button>
                <van-button
                  size="small"
                  type="primary"
                  @click="searchStudent"
                  >{{ lang.search }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div v-if="!isShowSearch">
          <van-cell-group inset>
            <van-field
              v-model="studentId"
              center
              :placeholder="lang.search_placeholder"
            >
              <template #button>
                <van-button
                  size="small"
                  type="primary"
                  @click="searchStudent"
                  >{{ lang.search }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
          <div class="no_student" v-if="students.length == 0">
            <img :src="common.getRes('data_empty.png')" />
            <span>{{ lang.no_student }}</span>
          </div>
          <div
            class="student"
            v-for="(student, index) in students"
            :key="index"
          >
            <div class="student_info">
              <img :src="student.headImg" />
              <div>
                <div class="df_center">
                  <span class="student_info_name"
                    >{{
                      language == "en"
                        ? student.en_name || student.cn_name
                        : student.cn_name || student.en_name
                    }}
                  </span>
                  <img
                    :class="[student.gender == 1 ? 'nan' : 'nv']"
                    :src="
                      common.getRes(
                        `${
                          student.gender == 1
                            ? 'shuyi_nansheng'
                            : 'shuyi_nvsheng'
                        }.png`
                      )
                    "
                  />
                </div>
                <span class="student_info_id">
                  ID：{{ student.admission_no }}
                </span>
              </div>
            </div>
            <div
              class="student_course"
              v-for="coursesItem in student['courses']"
              :key="coursesItem['id']"
            >
              <van-collapse v-model="students.activeCollapse" accordion>
                <van-collapse-item
                  :title="coursesItem['name']"
                  :value="`${lang.Available}:${coursesItem.lessons.length}`"
                  :name="coursesItem.id"
                >
                  <div
                    class="select_course"
                    v-for="(lessons, l) in coursesItem.lessons"
                    :key="l"
                  >
                    <span class="select_course_date">{{
                      lessons.class_date
                    }}</span>
                    <van-radio-group v-model="student.origin_lesson_id">
                      <van-radio :name="lessons.id"></van-radio>
                    </van-radio-group>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_btn" v-if="!isShowSearch">
      <van-button plain type="primary" @click="cancel">{{
        lang.cancel
      }}</van-button>
      <van-button type="primary" @click="submit">{{ lang.submit }}</van-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import request from "@/library/MyRequest";
import { useRouter } from "vue-router";
import VanNavbar from "@/components/common/van-navbar";
import Config from "@/library/Config";
import { Toast, Dialog } from "vant";
export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
  },
  props: {
    course: Object,
  },
  setup(props) {
    const common = Common;
    const router = useRouter();
    const courseData = JSON.parse(router.currentRoute.value.query.courseData);
    let state = reactive({
      common: common,
      lang: {
        route_misslesson: "",
        search: "",
        search_placeholder: "",
        no_student: "",
        Available: "",
        submit: "",
        cancel: "",
        confirm_misslesson: "",
        yes: "",
        no: "",
      },
      activeCollapse: ["1"],
      isShowSearch: true,
      studentId: "",
      students: [],
      checked: "1",
      language: common.getLocal("Language"),
    });
    common.initLanguage(state.lang);
    const searchStudent = async () => {
      var res = await request.get(
        `/lesson/search-student?lesson_id=${courseData.courseId}&keyword=${state.studentId}`
      );
      console.log("searchStudent", res);
      state.students = res.data.students;
      state.students.map((item) => {
        item["activeCollapse"] = item["courses"][0].id;
        item["headImg"] =
          item.avatar_url == null
            ? common.getRes("default_headImg.png")
            : item.avatar_url;
        // : Config["imgUrl"] + item.avatar_url;
      });
      state.isShowSearch = false;
    };
    const submit = () => {
      Dialog.confirm({
        title: state.lang.confirm_misslesson,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        submitStudent();
      });
    };
    const submitStudent = () => {
      console.log("state.students", state.students);
      let supplement = [];
      state.students.map((item) => {
        let { course } = item;
        let obj = {
          admission_no: item.admission_no,
          target_lesson_id: courseData.courseId,
          origin_lesson_id: "",
        };
        if (
          item["origin_lesson_id"] != undefined &&
          item["origin_lesson_id"] != ""
        ) {
          obj["origin_lesson_id"] = item["origin_lesson_id"];
          supplement.push(obj);
        }
      });
      console.log("supplement", supplement);
      // return;
      if (supplement.length > 0) {
        supplement.map(async (supplementItem) => {
          let data = new FormData();
          data.append("admission_no", supplementItem.admission_no);
          data.append("origin_lesson_id", supplementItem.origin_lesson_id);
          data.append("target_lesson_id", supplementItem.target_lesson_id);
          var res = await request.post(`lesson/supplement-lesson/`, data);
          if (res.status == 200) {
            console.log("成功");
          }
        });
        router.back();
      }
    };
    const cancel = () => {
      router.back();
    };
    return {
      ...toRefs(state),
      common,
      searchStudent,
      submitStudent,
      cancel,
      submit,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.searchstudent {
  width: 100%;
  padding: 80px 80px 30px;
  box-sizing: border-box;
  img {
    width: 526px;
    height: 526px;
  }
  /deep/.van-cell-group--inset {
    margin: 0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
    .van-cell {
      padding: 0;
      .van-field__control {
        height: 80px;
        background: #ffffff;
        padding: 10px 20px;
        font-size: 32px;
        text-align: center;
      }
    }
  }
  /deep/.van-field__button {
    padding: 0;

    .van-button--small {
      width: 156px;
      height: 80px;
    }
    .van-button--primary {
      color: #ffffff;
      background: #a5907e;
      border-color: #a5907e;
      font-size: 28px;
    }
  }
}
.hasStudent {
  padding: 40px 20px 30px;
  /deep/.van-cell-group--inset {
    box-shadow: none;
  }
}
.student {
  padding: 30px 20px;
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  .student_info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    text-align: left;
    span {
      display: block;
    }
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .student_info_name {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 30px;
      margin-right: 10px;
    }
    .student_info_id {
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
    }
  }
  .student_course {
    margin-bottom: 10px;
    /deep/.van-cell::after,
    .van-hairline--top-bottom::after,
    .van-hairline-unset--top-bottom::after {
      border: none;
    }
    /deep/.van-cell {
      padding: 0;
    }
    /deep/.van-cell__title {
      text-align: left;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 42px;
    }
    /deep/.van-cell__value {
      flex: none;
      width: 200px;
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a5907e;
      white-space: nowrap;
    }
    /deep/.van-cell__right-icon {
      font-size: 28px;
      color: #a5907e;
    }
    /deep/.van-collapse-item__content {
      padding-top: 0;
      padding-bottom: 0;
    }
    .select_course {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .select_course_date {
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
      }
    }
  }
}
.no_student {
  margin-top: 50px;
  text-align: center;
  span {
    display: block;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
  }
}

.footer_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px 20px;
  background-color: #fff;
  box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  /deep/.van-button {
    width: 50%;
    height: 88px;
    border-radius: 12px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 44px;
    padding: 20px 40px;
    &:nth-child(1) {
      border-color: #999999;
      color: #999999;
      margin-right: 20px;
    }
    &:nth-child(2) {
      background-color: #a5907e;
      color: #fff;
      border-color: #a5907e;
    }
  }
}

.df_center {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
